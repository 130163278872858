import { FC } from 'react';
import { useSlate } from 'slate-react';
import { CustomDropdown } from '../../../../../RichTextEditorV2/Toolbar/components/CustomDropdown';
import {
  BLOCKQUOTE_HEADING_TOOL,
  CODE_HEADING_TOOL,
  H1_HEADING_TOOL,
  H2_HEADING_TOOL,
  H3_HEADING_TOOL,
  H4_HEADING_TOOL,
  H5_HEADING_TOOL,
  H6_HEADING_TOOL,
  HEADING_TOOL_OPTIONS,
  NORMAL_HEADING_TOOL,
} from './constants/HeadingTool.constants';
import { IHeaderTool } from './HeadingTool.interfaces';
import { useStyles } from './HeadingTool.styles';

export const HeadingTool: FC<IHeaderTool> = ({ renderOptions }) => {
  const editor = useSlate();
  const classes = useStyles();

  return (
    <CustomDropdown
      className={classes.root}
      classes={{ popover: classes.popover }}
      editor={editor}
      placeholder="Normal"
      minWidth="116.56px"
      options={
        renderOptions
          ? renderOptions({
              NORMAL_HEADING_TOOL,
              H1_HEADING_TOOL,
              H2_HEADING_TOOL,
              H3_HEADING_TOOL,
              H4_HEADING_TOOL,
              H5_HEADING_TOOL,
              H6_HEADING_TOOL,
              BLOCKQUOTE_HEADING_TOOL,
              CODE_HEADING_TOOL,
            })
          : HEADING_TOOL_OPTIONS
      }
    />
  );
};
