import React from 'react';
import { IRTERenderElementProps } from '../../../RTEEditable.interfaces';
import { getJsxStyles } from '../../../utils/RTEEditable.utils';

const LinkSerialize: React.FC<IRTERenderElementProps> = ({
  attributes,
  element,
  children,
}) => {
  const styles: any = getJsxStyles(element?.style?.value);

  return (
    <a
      href={element.href}
      {...attributes}
      {...element.attr}
      target={element.target}
      style={styles}
    >
      {children}
    </a>
  );
};

export default LinkSerialize;
