import { FC } from 'react';
import { IRTERenderElementProps } from '../../../../../../RTEEditable.interfaces';

export const ListItemTextElement: FC<IRTERenderElementProps> = (props) => {
  const { element, children } = props;
  const attributes = props.attributes ?? {};

  return (
    <div {...element.attr} {...attributes}>
      {children}
    </div>
  );
};
