import { jsx } from 'slate-hyperscript';
import { DESERIALIZER_KEYS } from '../constants/RTE.constants';
import {
  FONT_FAMILIES,
  RTE_NODE_TYPES,
} from '../modules/RTEEditable/constants/RTEEditable.constants';
import { getJsxStyles } from '../modules/RTEEditable/utils/RTEEditable.utils';
import { FONT_SIZES } from '../modules/RTEToolbar/modules/FontSizeTool/constants/FontSizeTool.constants';

/**
 * Deserialize HTML elements into Slate JSX elements.
 * @param {any} el - The HTML element to deserialize.
 * @param {Object} markAttributes - Additional attributes.
 * @returns {Array} - An array of Slate JSX elements.
 */
export const deserialize = (el: any, markAttributes = {}) => {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx('text', markAttributes, el.textContent);
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes: any = { ...markAttributes };

  const styles: any = getJsxStyles(el.attributes.style?.value);

  // define attributes for text nodes
  switch (el.nodeName.toLowerCase()) {
    case DESERIALIZER_KEYS.strong:
    case DESERIALIZER_KEYS.b:
      nodeAttributes.bold = true;
      break;
    case DESERIALIZER_KEYS.em:
    case DESERIALIZER_KEYS.i:
      nodeAttributes.italic = true;
      break;
    case DESERIALIZER_KEYS.code:
      nodeAttributes.code = true;
      break;
    case DESERIALIZER_KEYS.u:
      nodeAttributes.underline = true;
      break;
    case DESERIALIZER_KEYS.del:
      nodeAttributes.strikethrough = true;
      break;
  }

  if (styles.color) nodeAttributes.color = styles.color;

  if (styles.fontSize) {
    const sizeType = Object.keys(FONT_SIZES).find(
      (type) => FONT_SIZES[type] === styles.fontSize
    );
    nodeAttributes.fontSize = sizeType;
  }

  if (styles.fontFamily) {
    const fontFamilyType = Object.keys(FONT_FAMILIES).find(
      (type) =>
        FONT_FAMILIES[type as keyof typeof FONT_FAMILIES] === styles.fontFamily
    );
    nodeAttributes.fontFamily = fontFamilyType;
  }

  if (styles.backgroundColor) nodeAttributes.bgColor = styles.backgroundColor;

  if (
    styles.fontWeight === DESERIALIZER_KEYS.bold ||
    styles.fontWeight === DESERIALIZER_KEYS.sevenHundred
  ) {
    nodeAttributes.bold = true;
  }
  if (styles.fontStyle === DESERIALIZER_KEYS.italic)
    nodeAttributes.italic = true;

  if (styles.textDecoration) {
    const textDecorationStyles = styles.textDecoration.split(' ');
    textDecorationStyles.forEach((styleMap: string) => {
      if (styleMap === DESERIALIZER_KEYS.underline)
        nodeAttributes.underline = true;
      if (styleMap === DESERIALIZER_KEYS.lineThrough)
        nodeAttributes.strikethrough = true;
    });
  }

  const children: any = Array.from(el.childNodes)
    .map((node) => deserialize(node, nodeAttributes))
    .flat();

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, DESERIALIZER_KEYS.emptyString));
  }

  if (el?.nodeName?.toLowerCase() === DESERIALIZER_KEYS.A.toLowerCase()) {
    const imgEl = children[0]; // TODO: why first child, mention reason and refactor

    if (imgEl.type === RTE_NODE_TYPES.IMAGE) {
      const { justifyContent } = getJsxStyles(imgEl.style?.value);

      return jsx(
        'element',
        {
          type: RTE_NODE_TYPES.IMAGE,
          url: imgEl.url,
          alt: imgEl.alt,
          style: imgEl.style,
          redirectTo: el.getAttribute('href'),
          justifyContent,
        },
        imgEl.children
      );
    }
  }

  switch (el.nodeName) {
    case DESERIALIZER_KEYS.BODY:
      return jsx('fragment', {}, children);
    case DESERIALIZER_KEYS.BR:
      return jsx('element', { style: el.attributes.style }, [
        { text: DESERIALIZER_KEYS.emptyString },
      ]);
    case DESERIALIZER_KEYS.BLOCKQUOTE:
      return jsx(
        'element',
        { type: RTE_NODE_TYPES.BLOCKQUOTE, style: el.attributes.style },
        children
      );
    case DESERIALIZER_KEYS.DIV: {
      const { textAlign } = getJsxStyles(el.attributes.style?.value);

      const alignType = {
        center: RTE_NODE_TYPES.ALIGN_CENTER,
        right: RTE_NODE_TYPES.ALIGN_RIGHT,
      };

      return jsx(
        'element',
        {
          type:
            el.parentElement?.nodeName === DESERIALIZER_KEYS.LI
              ? RTE_NODE_TYPES.LIST_ITEM_TEXT
              : alignType[textAlign as keyof typeof alignType] ||
                RTE_NODE_TYPES.ALIGN_LEFT,
          style: el.attributes.style,
        },
        children
      );
    }
    case DESERIALIZER_KEYS.P:
      return jsx(
        'element',
        { type: RTE_NODE_TYPES.PARAGRAPH, style: el.attributes.style },
        children
      );
    case DESERIALIZER_KEYS.H1:
      return jsx(
        'element',
        { type: RTE_NODE_TYPES.H1, style: el.attributes.style },
        children
      );
    case DESERIALIZER_KEYS.H2:
      return jsx(
        'element',
        { type: RTE_NODE_TYPES.H2, style: el.attributes.style },
        children
      );
    case DESERIALIZER_KEYS.H3:
      return jsx(
        'element',
        { type: RTE_NODE_TYPES.H3, style: el.attributes.style },
        children
      );
    case DESERIALIZER_KEYS.H4:
      return jsx(
        'element',
        { type: RTE_NODE_TYPES.H4, style: el.attributes.style },
        children
      );
    case DESERIALIZER_KEYS.H5:
      return jsx(
        'element',
        { type: RTE_NODE_TYPES.H5, style: el.attributes.style },
        children
      );
    case DESERIALIZER_KEYS.H6:
      return jsx(
        'element',
        { type: RTE_NODE_TYPES.H6, style: el.attributes.style },
        children
      );
    case DESERIALIZER_KEYS.IMG: {
      const { justifyContent } = getJsxStyles(el.attributes.style?.value);
      return jsx(
        'element',
        {
          type: RTE_NODE_TYPES.IMAGE,
          url: el.getAttribute('src'),
          alt: el.getAttribute('alt'),
          style: el.attributes.style,
          justifyContent,
        },
        children
      );
    }
    case DESERIALIZER_KEYS.A:
      return jsx(
        'element',
        {
          type: RTE_NODE_TYPES.LINK,
          href: el.getAttribute(DESERIALIZER_KEYS.href),
          style: el.attributes.style,
        },
        children
      );
    case DESERIALIZER_KEYS.UL:
      return jsx(
        'element',
        {
          type: RTE_NODE_TYPES.UNORDERED_LIST,
          href: el.getAttribute(DESERIALIZER_KEYS.href),
          style: el.attributes.style,
        },
        children
      );
    case DESERIALIZER_KEYS.OL:
      return jsx(
        'element',
        {
          type: RTE_NODE_TYPES.ORDERED_LIST,
          href: el.getAttribute(DESERIALIZER_KEYS.href),
          style: el.attributes.style,
        },
        children
      );
    case DESERIALIZER_KEYS.LI:
      return jsx(
        'element',
        {
          type: RTE_NODE_TYPES.LIST_ITEM,
          href: el.getAttribute(DESERIALIZER_KEYS.href),
          style: el.attributes.style,
        },
        children
      );
    case DESERIALIZER_KEYS.TABLE:
      return jsx(
        'element',
        {
          type: RTE_NODE_TYPES.TABLE,
          style: el.attributes.style,
        },
        children
      );
    case DESERIALIZER_KEYS.TBODY:
      return jsx(
        'element',
        {
          type: RTE_NODE_TYPES.TABLE_BODY,
          style: el.attributes.style,
        },
        children
      );
    case DESERIALIZER_KEYS.TR:
      return jsx(
        'element',
        {
          type: RTE_NODE_TYPES.TABLE_ROW,
          style: el.attributes.style,
        },
        children
      );
    case DESERIALIZER_KEYS.TH:
      return jsx(
        'element',
        {
          type: RTE_NODE_TYPES.TABLE_HEAD,
          style: el.attributes.style,
        },
        children
      );
    case DESERIALIZER_KEYS.TD:
      return jsx(
        'element',
        {
          type: RTE_NODE_TYPES.TABLE_CELL,
          style: el.attributes.style,
        },
        children
      );
    default:
      return children;
  }
};

export const deserializer = (html: string, disableWhiteSpaces = true) => {
  let body = (html || '<p></p>').trim();

  if (disableWhiteSpaces) body = body.replace(/>\s+</g, '><');

  const document = new DOMParser().parseFromString(body, 'text/html');
  return deserialize(document.body);
};
