import { FC } from 'react';
import { IRTERenderElementProps } from '../../../../RTEEditable.interfaces';

export const BlockQuoteElement: FC<IRTERenderElementProps> = (props) => {
  const { element, children } = props;
  const attributes = props.attributes ?? {};

  return (
    <blockquote
      {...attributes}
      {...element.attr}
      style={{
        borderLeft: '2px solid #ddd',
        marginLeft: 0,
        marginRight: 0,
        paddingLeft: '10px',
        color: '#aaa',
        fontStyle: 'italic',
      }}
    >
      {children}
    </blockquote>
  );
};
