import FormatIndentIncrease from '@material-ui/icons/FormatIndentIncrease';
import { ListsEditor } from '@prezly/slate-lists';
import { useSlate } from 'slate-react';
import { isBlockActive } from '../../../../../RichTextEditorV2/utils/SlateUtilityFunctions';
import { ToolbarButton } from '../ToolbarButton/ToolbarButton';
import { IToolbarButton } from '../ToolbarButton/ToolbarButton.interfaces';
import { SLATE_LIST_INDENTS } from '../../constants/RTEToolbar.lists';

export const IndentRightTool = () => {
  const editor = useSlate();

  const handleToggleUnorderedList: IToolbarButton['onMouseDown'] = (e) => {
    e.preventDefault();
    ListsEditor.increaseDepth(editor as ListsEditor);
  };

  return (
    <ToolbarButton
      active={isBlockActive(editor, SLATE_LIST_INDENTS.indent_right)}
      onMouseDown={handleToggleUnorderedList}
    >
      <FormatIndentIncrease />
    </ToolbarButton>
  );
};
