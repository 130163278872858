import { FC } from 'react';
import { IRTERenderElementProps } from '../../../../RTEEditable.interfaces';
import { RTE_HEADING_NODE_TYPES } from '../../../../constants/RTEEditable.constants';

export const HeadingElement: FC<IRTERenderElementProps> = (props) => {
  const { element, children } = props;
  const { type } = element;
  const HeadingTag =
    type as typeof RTE_HEADING_NODE_TYPES[keyof typeof RTE_HEADING_NODE_TYPES];
  const attributes = props.attributes ?? {};

  return (
    <HeadingTag {...attributes} {...element.attr}>
      {children}
    </HeadingTag>
  );
};
