export const FONT_SIZES_KEYS = {
  small: 'small',
  normal: 'normal',
  medium: 'medium',
  huge: 'huge',
};

export const FONT_SIZE_TOOL_OPTIONS = [
  { id: 1, label: 'Small', value: FONT_SIZES_KEYS.small },
  { id: 2, label: 'Normal', value: FONT_SIZES_KEYS.normal },
  { id: 3, label: 'Medium', value: FONT_SIZES_KEYS.medium },
  { id: 4, label: 'Huge', value: FONT_SIZES_KEYS.huge },
];

export const FONT_SIZES = {
  [FONT_SIZES_KEYS.small]: '0.75em',
  [FONT_SIZES_KEYS.normal]: '1em',
  [FONT_SIZES_KEYS.medium]: '1.75em',
  [FONT_SIZES_KEYS.huge]: '2.5em',
};
