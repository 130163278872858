import { RTE_NODE_TYPES } from '../../../../RTEEditable/constants/RTEEditable.constants';

export const NORMAL_HEADING_TOOL = {
  id: '1',
  label: 'Normal',
  value: { type: 'block', format: 'normal' },
};
export const H1_HEADING_TOOL = {
  id: '2',
  label: 'H1',
  value: { type: 'block', format: RTE_NODE_TYPES.H1 },
};
export const H2_HEADING_TOOL = {
  id: '3',
  label: 'H2',
  value: { type: 'block', format: RTE_NODE_TYPES.H2 },
};
export const H3_HEADING_TOOL = {
  id: '4',
  label: 'H3',
  value: { type: 'block', format: RTE_NODE_TYPES.H3 },
};
export const H4_HEADING_TOOL = {
  id: '5',
  label: 'H4',
  value: { type: 'block', format: RTE_NODE_TYPES.H4 },
};
export const H5_HEADING_TOOL = {
  id: '6',
  label: 'H5',
  value: { type: 'block', format: RTE_NODE_TYPES.H5 },
};
export const H6_HEADING_TOOL = {
  id: '7',
  label: 'H6',
  value: { type: 'block', format: RTE_NODE_TYPES.H6 },
};

export const BLOCKQUOTE_HEADING_TOOL = {
  id: '8',
  label: 'Blockquote',
  value: { type: 'block', format: RTE_NODE_TYPES.BLOCKQUOTE },
};

export const CODE_HEADING_TOOL = {
  id: '9',
  label: 'Code',
  value: { type: 'mark', format: 'code' },
};

export const HEADING_TOOL_OPTIONS = [
  NORMAL_HEADING_TOOL,
  H1_HEADING_TOOL,
  H2_HEADING_TOOL,
  H3_HEADING_TOOL,
  H4_HEADING_TOOL,
  H5_HEADING_TOOL,
  H6_HEADING_TOOL,
  BLOCKQUOTE_HEADING_TOOL,
  CODE_HEADING_TOOL,
];
