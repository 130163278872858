import React from 'react';
import { IRTERenderElementProps } from '../../../../RTEEditable.interfaces';

const Table: React.FC<IRTERenderElementProps> = ({ children }) => {
  return (
    <table
      style={{
        width: '100%',
        borderCollapse: 'collapse',
        border: '1.5px solid #272522',
        tableLayout: 'fixed',
      }}
    >
      {children}
    </table>
  );
};

export default Table;
