import { Box, IconButton, Popover, useMediaQuery } from '@material-ui/core';
import {
  FormatAlignCenter as AlignCenterIcon,
  FormatAlignLeft as AlignLeftIcon,
  FormatAlignRight as AlignRightIcon,
  Close,
  Delete,
  Delete as DeleteIcon,
  EditRounded,
  Link as LinkIcon,
} from '@material-ui/icons';
import classnames from 'classnames';
import isUrl from 'is-url';
import React, { useEffect, useRef, useState } from 'react';
import { Rnd as Resizable } from 'react-rnd';
import { Transforms } from 'slate';
import { ReactEditor, useFocused, useSelected, useSlate } from 'slate-react';
import Button from '../../../../../../../Button/Button';
import Input from '../../../../../../../Input/Input';
import MobileModal from '../../../../../../../MobileModal/MobileModal';
import { getJsxStyles } from '../../../../utils/RTEEditable.utils';
import ControlButton from '../Button/Button';
import Divider from '../Divider/Divider';
import { useStyles } from './Image.styles';
import { IRTERenderElementProps } from '../../../../RTEEditable.interfaces';

const IMAGE_ALIGNS_OPTIONS = [
  { align: 'left', icon: <AlignLeftIcon /> },
  { align: 'center', icon: <AlignCenterIcon /> },
  { align: 'right', icon: <AlignRightIcon /> },
];

// TODO: refactor and fix typescript
const ImageLinkForm = ({
  value,
  onChange,
  inputProps,
  onDelete,
  primaryBtnProps,
  secondaryBtnProps,
  showDelete,
}: any) => {
  const classes = useStyles();

  return (
    <Box className={classes.redirectToLink}>
      <Box className={classes.linkInputBox}>
        <Input
          size="thin"
          placeholder="Enter link"
          value={value}
          onChange={onChange}
          autoFocus
          wrapperClassName={classes.input}
          {...inputProps}
        />
        {showDelete && (
          <IconButton onMouseDown={onDelete} className={classes.iconBtn}>
            <Delete />
          </IconButton>
        )}
      </Box>
      <Box className={classes.linkCta}>
        {secondaryBtnProps && (
          <Button
            className={classes.cancelCta}
            size="thin"
            color="subtleSecondary"
            {...secondaryBtnProps}
          >
            {secondaryBtnProps.label}
          </Button>
        )}
        {primaryBtnProps && (
          <Button size="thin" color="primary" {...primaryBtnProps}>
            {primaryBtnProps.label}
          </Button>
        )}
      </Box>
    </Box>
  );
};

const ImageElement: React.FC<IRTERenderElementProps> = ({
  attributes,
  element,
  children,
}) => {
  const isDesktop = useMediaQuery('(min-device-width: 767px)');

  let editor = useSlate() as any;

  const styles: any = getJsxStyles(element?.style?.value);

  const { url, alt, redirectTo } = element;

  const selected = useSelected();
  const focused = useFocused();

  const [size, setSize] = useState({
    height: styles.height,
    width: styles.width,
  });
  const [selectedAlign, setSelectedAlign] = useState(
    styles['justifyContent'] || 'left'
  );
  const [openAddLink, setOpenAddLink] = useState(false);
  const [redirectToLink, setRedirectToLink] = useState(redirectTo);
  const [openControls, setOpenControls] = useState(false);
  const [linkAnchorEl, setLinkAnchorEl] = useState(null);
  const [selection, setSelection] = useState(null);

  const redirectToLinkError =
    redirectToLink && !isUrl(redirectToLink)
      ? { error: true, helperText: 'Invalid URL' }
      : {};

  const path = ReactEditor.findPath(editor, element);

  const imageRef = useRef(null);

  const classes = useStyles();

  const AlignStyles: any = {
    left: classes.left,
    right: classes.right,
    center: classes.center,
  };

  useEffect(() => {
    setRedirectToLink(redirectTo);
  }, [redirectTo]);

  // TODO: define it at the place of cause
  // ref: https://react.dev/learn/you-might-not-need-an-effect
  useEffect(() => {
    Transforms.setNodes(
      editor,
      {
        height: size.height,
        width: size.width,
        justifyContent: selectedAlign,
      } as any,
      { at: element.focus?.path }
    );
  }, [size, selectedAlign]);

  const setFocus = () => {
    // TODO: figure out why this timeout is needed
    if (selection) {
      setTimeout(() => {
        Transforms.select(editor, selection);
      }, 100);
    }
  };

  const handleAlign = (align: string) => (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedAlign(align);
  };

  const handleDeleteLink = () => {
    Transforms.setNodes(
      editor,
      {
        redirectTo: null,
      } as any,
      { at: element.focus?.path }
    );

    setFocus();
  };

  const handleDeleteImage = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    Transforms.removeNodes(editor, { at: path });
  };

  const handleAddLink = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setSelection(editor.selection);
    setOpenAddLink(true);
    setLinkAnchorEl(e.currentTarget);
  };

  const handleRemoveLink = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setRedirectToLink(null);
    handleDeleteLink();
    setOpenAddLink(false);
  };

  const handleAddRedirectToLink = (e: React.SyntheticEvent) => {
    e.preventDefault();

    Transforms.setNodes(
      editor,
      {
        redirectTo: redirectToLink,
      } as any,
      { at: element.focus?.path }
    );
    setOpenAddLink(false);

    setFocus();
  };

  const handleCloseAddLink = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (redirectTo) setRedirectToLink(redirectTo);
    setOpenAddLink(false);

    // TODO: figure out why this timeout is needed
    setTimeout(() => {
      Transforms.select(editor, selection);
    }, 100);
  };

  const AlignControls = IMAGE_ALIGNS_OPTIONS.map(({ align, icon }, index) => (
    <IconButton
      key={index}
      className={classnames(
        classes.iconBtn,
        selectedAlign === align && classes.active
      )}
      onClick={handleAlign(align)}
      onMouseDown={handleAlign(align)}
    >
      {icon}
    </IconButton>
  ));

  const DeleteImageBtn = (
    <IconButton
      onClick={handleDeleteImage}
      onMouseDown={handleDeleteImage}
      className={classes.iconBtn}
    >
      <DeleteIcon />
    </IconButton>
  );

  const CloseControlsBtn = (
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
        setOpenControls(false);
      }}
      className={classes.iconBtn}
    >
      <Close />
    </IconButton>
  );

  const AddLinkBtn = (
    <ControlButton
      active={openAddLink || isUrl(redirectTo)}
      onClick={handleAddLink}
      onMouseDown={handleAddLink}
    >
      <LinkIcon />
    </ControlButton>
  );

  const ResizeBtn = (
    <IconButton
      onClick={(e) => {
        setOpenControls((prev) => !prev);
      }}
      className={classnames(classes.iconBtn, classes.resizeBtn)}
    >
      <div className={classes.resizeIcon}>
        <EditRounded />

        {/* TODO: remove inline styles */}
        <span style={{ fontSize: '14px' }}>Click to edit</span>
      </div>
    </IconButton>
  );

  const RenderImage = (
    <div
      className={`${classes.pos_absolute} ${openControls ? classes.w100 : ''} ${
        AlignStyles[selectedAlign]
      }`}
    >
      <img
        className={classes.img}
        ref={imageRef}
        alt={alt}
        src={url}
        style={{
          ...styles,
          ...size,
          maxWidth: '100%',
          maxHeight: '100vh',
          justifyContent: selectedAlign,
          whiteSpace: 'nowrap',
        }}
        onError={() => {
          setSize((prev) => ({ ...prev, height: '18px' }));
        }}
        onLoad={(e) => {
          const tempImage = new Image();
          tempImage.src = url;

          tempImage.onload = (e) => {
            if (!size.height || !size.width)
              setSize({
                height: styles.height || imageRef.current?.clientHeight,
                width: styles.width || tempImage.width,
              });
          };
        }}
      />
    </div>
  );

  const ResizableWrapper = (
    <Resizable
      className={`${selected && focused ? classes.box_shadow : ''} ${
        AlignStyles[selectedAlign]
      } ${selectedAlign === 'center' ? classes.centerImage : ''}`}
      default={{
        width: size.width,
        height: size.height,
      }}
      lockAspectRatio
      size={size}
      maxWidth="100%"
      onResize={(e, fir, ref) => {
        setSize({ height: ref.style.height, width: ref.style.width });
      }}
    >
      {RenderImage}

      {!isDesktop && (
        <MobileModal
          header="Add link to image"
          open={openAddLink}
          onClose={handleCloseAddLink}
          primaryAction={{
            label: 'Add',
            onClick: handleAddRedirectToLink,
            btnProps: {
              disabled: !redirectToLink || redirectToLinkError.error,
            },
          }}
          secondaryAction={{
            label: 'Cancel',
            onClick: handleCloseAddLink,
          }}
        >
          <Box className={classes.mobileModal}>
            <ImageLinkForm
              inputProps={{
                size: 'small',
                fullWidth: true,
                placeholder: 'Enter link',
                value: redirectToLink,
                onChange: (e: any) => setRedirectToLink(e.target.value),
              }}
              showDelete={redirectTo}
              onDelete={handleRemoveLink}
            />
          </Box>
        </MobileModal>
      )}
    </Resizable>
  );

  return (
    <div
      {...attributes}
      {...element.attr}
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: selectedAlign,
        position: 'relative',
      }}
      contentEditable={false}
    >
      {openControls && (
        <Box className={classes.controlsContainer}>
          <Box className={classes.imageControls}>
            {AlignControls}
            <Divider />
            {AddLinkBtn}
            <Divider />
            {DeleteImageBtn}

            {
              <>
                <Divider />
                {CloseControlsBtn}
              </>
            }
          </Box>

          {isDesktop && (
            <Popover
              open={openAddLink}
              anchorEl={linkAnchorEl}
              style={{ zIndex: 9999 }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              classes={{ paper: classes.linkPopoverPaper }}
            >
              <ImageLinkForm
                inputProps={{
                  size: 'thin',
                  placeholder: 'Enter link',
                  value: redirectToLink,
                  onChange: (e: any) => setRedirectToLink(e.target.value),
                }}
                secondaryBtnProps={{
                  label: 'Cancel',
                  size: 'thin',
                  color: 'subtleSecondary',
                  onClick: handleCloseAddLink,
                }}
                primaryBtnProps={{
                  label: 'Add',
                  disabled: !redirectToLink || redirectToLinkError.error,
                  size: 'thin',
                  color: 'primary',
                  onClick: handleAddRedirectToLink,
                }}
                showDelete={redirectTo}
                onDelete={handleRemoveLink}
              />
            </Popover>
          )}
        </Box>
      )}
      <div
        className={classes.embed}
        contentEditable={false}
        style={{
          display: 'flex',
          width: '100%',
          height: size.height,
          position: 'relative',
        }}
      >
        {openControls ? ResizableWrapper : RenderImage}

        {!openControls && ResizeBtn}
      </div>
      {children}
    </div>
  );
};
export default ImageElement;
