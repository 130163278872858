// @ts-nocheck
import { Editor, Element as SlateElement, Transforms } from 'slate';
import HtmlCode from '../Elements/CodeToText/HtmlCode';
import Image from '../Elements/Embed/Image';
import Link from '../Elements/Link/Link';
import Table from '../Elements/Table/Table';
import HtmlCodeSerialize from './SerializeComponent/HtmlCodeSerialize';
import ImageSerialize from './SerializeComponent/ImageSerialize';
import LinkSerialize from './SerializeComponent/LinkSerialize';
import { convertStringToCSSObject } from './FilterPasteElements/filterPasteElements';
import { FONT_SIZES } from '../../RichTextEditorV3/modules/RTEToolbar/modules/FontSizeTool/constants/FontSizeTool.constants';

export const TYPES = {
  PARAGRAPH: 'paragraph',
  SPAN: 'span',
  ORDERED_LIST: 'ordered-list',
  UNORDERED_LIST: 'unordered-list',
  LIST_ITEM: 'list-item',
  LIST_ITEM_TEXT: 'list-item-text',
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6',
  BLOCKQUOTE: 'blockquote',
  ALIGN_LEFT: 'alignLeft',
  ALIGN_CENTER: 'alignCenter',
  ALIGN_RIGHT: 'alignRight',
  LINK: 'link',
  TABLE: 'table',
  TABLE_BODY: 'table-body',
  TABLE_ROW: 'table-row',
  TABLE_CELL: 'table-cell',
  TABLE_HEAD: 'table-head',
  IMAGE: 'image',
  HTML_CODE: 'html-code',
};

export const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

const alignment = [TYPES.ALIGN_LEFT, TYPES.ALIGN_CENTER, TYPES.ALIGN_RIGHT];
const list_types = [TYPES.ORDERED_LIST, TYPES.UNORDERED_LIST];

export const fontFamilyMap = {
  sans: 'Helvetica,Arial, sans serif',
  serif: 'Georgia, Times New Roaman,serif',
  monospace: 'Monaco, Courier New,monospace',
};

export const toggleBlock = (editor: any, format: string) => {
  const isActive = isBlockActive(editor, format);
  const isList = list_types.includes(format);
  const isIndent = alignment.includes(format);
  const isAligned = alignment.some((alignmentType) =>
    isBlockActive(editor, alignmentType)
  );

  /*If the node is already aligned and change in indent is called we should unwrap it first and split the node to prevent
    messy, nested DOM structure and bugs due to that.*/
  if (isAligned && isIndent) {
    Transforms.unwrapNodes(editor, {
      match: (n) =>
        alignment.includes(
          !Editor.isEditor(n) && SlateElement.isElement(n) && n.type
        ),
      split: true,
    });
  }

  /* Wraping the nodes for alignment, to allow it to co-exist with other block level operations*/
  if (isIndent) {
    Transforms.wrapNodes(editor, {
      type: format,
      children: [],
    });
    return;
  }
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      list_types.includes(
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type
      ),
    split: true,
  });

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  });

  if (isList && !isActive) {
    Transforms.wrapNodes(editor, {
      type: format,
      children: [],
    });
  }
};
export const addMarkData = (editor: any, data: any) => {
  Editor.addMark(editor, data.format, data.value);
};
export const toggleMark = (editor: any, format: string) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};
export const isMarkActive = (editor: any, format: string) => {
  const marks: any = Editor.marks(editor);

  return marks ? marks[format] === true : false;
};

export const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  );

  return !!match;
};

export const getValueFromOptions = (editor: any, options: any[]) => {
  const optionBlock = options.find((option) => {
    const [match] = Array.from(
      Editor.nodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.type === option.value.format,
      })
    );

    return !!match;
  });

  const marks = Editor.marks(editor);

  const optionMark =
    marks && options.find((option) => marks[option.value.format]);

  return optionBlock || optionMark || null;
};

const EditorElement = (props: any) => {
  return getBlock(props);
};
const EditorLeaf = (props: any) => {
  let { attributes, children, leaf } = props;

  children = getMarked(leaf, children);
  return (
    <span
      style={leaf.text === '' ? { paddingLeft: '0.1px' } : null}
      {...attributes}
    >
      {children}
    </span>
  );
};

export const renderElement = (props: any) => <EditorElement {...props} />;

export const renderLeaf = (props: any) => {
  return <EditorLeaf {...props} />;
};

export const activeMark = (editor: any, format: string) => {
  const defaultMarkData = {
    color: 'black',
    bgColor: 'black',
    fontSize: 'normal',
    fontFamily: 'sans',
  };
  const marks = Editor.marks(editor);
  const defaultValue = defaultMarkData[format];
  return marks?.[format] ?? defaultValue;
};

export const getMarked = (leaf: any, children: any) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = (
      <code
        style={{
          fontFamily:
            "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace",
        }}
      >
        {children}
      </code>
    );
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }
  if (leaf.strikethrough) {
    children = <del>{children}</del>;
  }
  if (leaf.underline) {
    children = <u>{children}</u>;
  }
  if (leaf.superscript) {
    children = <sup>{children}</sup>;
  }
  if (leaf.subscript) {
    children = <sub>{children}</sub>;
  }
  if (leaf.color) {
    children = <span style={{ color: leaf.color }}>{children}</span>;
  }
  if (leaf.bgColor) {
    children = (
      <span style={{ backgroundColor: leaf.bgColor }}>{children}</span>
    );
  }
  if (leaf.fontSize) {
    const size = FONT_SIZES[leaf.fontSize];
    children = <span style={{ fontSize: size }}>{children}</span>;
  }
  if (leaf.fontFamily) {
    const family = fontFamilyMap[leaf.fontFamily];
    children = <span style={{ fontFamily: family }}>{children}</span>;
  }
  return children;
};

export const getJsxStyles = (styles = '') => {
  const o = styles.split(';').map((value) => {
    const arr = value.split(':');
    const key = arr[0];
    const val = arr[1];

    const newKey = key
      .split('-')
      .map((item, index) =>
        (index === 0
          ? item
          : item.charAt(0).toUpperCase() + item.slice(1)
        ).trim()
      )
      .join('');

    const ans = {};
    ans[newKey] = val?.trim();

    return ans;
  });

  return o.reduce((prev, style) => ({ ...prev, ...style }), {});
};

export const getBlock = (props: any, isSerialize: any) => {
  const { element, children } = props;
  const attributes = props.attributes ?? {};
  switch (element.type) {
    case TYPES.PARAGRAPH:
      return (
        <p
          {...element.attr}
          {...attributes}
          style={{
            margin: 0,
            minHeight: '18px',
            whiteSpace: 'pre-wrap',
            ...element?.styles,
            ...convertStringToCSSObject(element?.style?.value),
          }}
        >
          {children}
        </p>
      );
    case TYPES.ORDERED_LIST:
      return (
        <ol {...element.attr} {...attributes} style={{ padding: 'revert' }}>
          {children}
        </ol>
      );
    case TYPES.UNORDERED_LIST:
      return (
        <ul {...element.attr} {...attributes} style={{ padding: 'revert' }}>
          {children}
        </ul>
      );
    case TYPES.LIST_ITEM:
      return (
        <li
          {...element.attr}
          {...attributes}
          style={{
            listStylePosition: 'outside',
          }}
        >
          {children}
        </li>
      );
    case TYPES.LIST_ITEM_TEXT:
      return (
        <div {...element.attr} {...attributes}>
          {children}
        </div>
      );
    case TYPES.H1:
      return (
        <h1 {...attributes} {...element.attr}>
          {children}
        </h1>
      );
    case TYPES.H2:
      return (
        <h2 {...attributes} {...element.attr}>
          {children}
        </h2>
      );
    case TYPES.H3:
      return (
        <h3 {...attributes} {...element.attr}>
          {children}
        </h3>
      );
    case TYPES.H4:
      return (
        <h4 {...attributes} {...element.attr}>
          {children}
        </h4>
      );
    case TYPES.H5:
      return (
        <h5 {...attributes} {...element.attr}>
          {children}
        </h5>
      );
    case TYPES.H6:
      return (
        <h6 {...attributes} {...element.attr}>
          {children}
        </h6>
      );
    case TYPES.BLOCKQUOTE:
      return (
        <blockquote
          {...attributes}
          {...element.attr}
          style={{
            borderLeft: '2px solid #ddd',
            marginLeft: 0,
            marginRight: 0,
            paddingLeft: '10px',
            color: '#aaa',
            fontStyle: 'italic',
          }}
        >
          {children}
        </blockquote>
      );
    case TYPES.ALIGN_LEFT:
      return (
        <div
          style={{
            listStylePosition: 'inside',
            minHeight: '18px',
          }}
          {...attributes}
          {...element.attr}
        >
          {children}
        </div>
      );
    case TYPES.ALIGN_CENTER:
      return (
        <div
          style={{
            textAlign: 'center',
            minHeight: '18px',
          }}
          {...attributes}
          {...element.attr}
        >
          {children}
        </div>
      );
    case TYPES.ALIGN_RIGHT:
      return (
        <div
          style={{
            textAlign: 'right',
            minHeight: '18px',
          }}
          {...attributes}
          {...element.attr}
        >
          {children}
        </div>
      );
    case TYPES.LINK:
      return isSerialize ? <LinkSerialize {...props} /> : <Link {...props} />;
    case TYPES.TABLE:
      return <Table {...props} />;
    case TYPES.TABLE_BODY:
      return <tbody {...attributes}>{children}</tbody>;
    case TYPES.TABLE_ROW:
      return <tr {...attributes}>{children}</tr>;
    case TYPES.TABLE_HEAD:
      return (
        <th
          {...element.attr}
          {...attributes}
          style={{
            border: '1px solid #272522',
            height: '50px',
            padding: '0 5px',
            overflowWrap: 'anywhere',
          }}
        >
          {children}
        </th>
      );
    case TYPES.TABLE_CELL:
      return (
        <td
          {...element.attr}
          {...attributes}
          style={{
            border: '1px solid #272522',
            height: '50px',
            padding: '0 5px',
            overflowWrap: 'anywhere',
          }}
        >
          {children}
        </td>
      );
    case TYPES.IMAGE:
      return isSerialize ? <ImageSerialize {...props} /> : <Image {...props} />;
    case TYPES.HTML_CODE:
      return isSerialize ? (
        <HtmlCodeSerialize {...props} />
      ) : (
        <HtmlCode {...props} />
      );
    default:
      return (
        <p
          {...element.attr}
          {...attributes}
          style={{
            margin: 0,
            minHeight: '18px',
            whiteSpace: 'pre-wrap',
          }}
        >
          {children}
        </p>
      );
  }
};
