import { FC } from 'react';
import { IRTERenderElementProps } from '../../../../RTEEditable.interfaces';

export const OrderedListElement: FC<IRTERenderElementProps> = (props) => {
  const { element, children } = props;
  const attributes = props.attributes ?? {};

  return (
    <ol {...element.attr} {...attributes} style={{ padding: 'revert' }}>
      {children}
    </ol>
  );
};
